import React from "react"

import { withGeneric } from "./withGeneric"

import { Accordion } from "../Accordion/Accordion"
import { RichText } from "../RichText/RichText"
import { SearchField } from "../Search/SearchField"
import { Container, Title } from "./Generic.styled"

export const Generic = withGeneric(
  ({ page: { content, topics, search, title } }: Props): JSX.Element => {
    return (
      <>
        <Container topSpacing={`md`} width={`xl`}>
          <Title>{title}</Title>
        </Container>

        {content && (
          <Container spacing={topics?.length ? `md` : `lg`} topSpacing={`md`} width={`md`}>
            <RichText>{content}</RichText>
          </Container>
        )}

        {search && (
          <Container spacing={`md`} width={`md`}>
            <SearchField large />
          </Container>
        )}

        {topics?.length ? (
          <Container spacing={`lg`} width={`md`}>
            <Accordion
              expanded={!search}
              highlight={search}
              items={topics?.map(({ content, title }) => ({
                content,
                title,
              }))}
            />
          </Container>
        ) : null}
      </>
    )
  }
)

export interface Props {
  title: string
  content: string
  topics?: Array<{
    content: string
    title: string
  }>
  search?: boolean
}
