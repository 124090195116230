import React from "react"
import { graphql } from "gatsby"

import { Generic as Template } from "../components/Generic/Generic"

export const query = graphql`
  query($id: String!) {
    page: sanityPageGeneric(_id: { eq: $id }) {
      title
      search
      slug {
        current
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      topics: _rawTopics(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
