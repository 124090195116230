import tw, { styled } from "twin.macro"

import { StyledInput } from "../../styled/Forms"

export const Field = styled.div`
  ${tw`flex items-center flex-row-reverse lg:flex-row justify-between bg-grey-lightest lg:bg-grey-pale rounded pr-4 lg:pr-0 lg:pl-4 w-full shadow-sm lg:shadow-none`}
  ${({ large }) => large && tw`bg-white border border-grey-pale lg:bg-white lg:shadow-sm`}
`
export const Input = styled(StyledInput)`
  ${tw`bg-transparent border-0 shadow-none py-3 px-4`}
  ${({ large }) => large && tw`py-5`}
`
