import React, { useState } from "react"

import { withAccordion } from "./withAccordion"

import { Icon } from "../Icon/Icon"
import { RichText } from "../RichText/RichText"
import { Section, List, Item, Button, Title, Content } from "./Accordion.styled"

export const Accordion = withAccordion(
  ({ expanded, highlight, items = [], active, handleActive }: Props): JSX.Element => (
    <Section>
      <List>
        {items.map((item, index) => {
          const isActive = index === active
          return (
            <Item key={index} expanded={expanded}>
              <Button onClick={() => handleActive(!isActive ? index : null)}>
                <Title active={isActive} highlight={highlight}>
                  {item.title}
                </Title>
                <Icon name={isActive ? "minus" : "plus"} size={"s"} colour={"grey"} />
              </Button>
              <Content active={isActive} as={`div`}>
                <RichText>{item.content}</RichText>
              </Content>
            </Item>
          )
        })}
      </List>
    </Section>
  )
)

export interface Props {
  items: Array<{
    title: string
    content?: string
  }>
  expanded?: boolean
  highlight?: boolean
  active?: number
  handleActive?: (index: number) => void
}
