import tw, { styled } from "twin.macro"

export const Section = tw.section`block mb-8`
export const List = tw.ul`block`
export const Item = styled.li`
  ${tw`border-b border-grey-pale py-4 last:border-none`}
  ${({ expanded }) => expanded && tw`py-6`}
`
export const Button = tw.button`w-full flex justify-between content-center items-center cursor-pointer focus:outline-none`
export const Title = styled.p`
  ${tw`font-bold text-grey-dark text-left`}
  ${({ active, highlight }) => highlight && !active && tw`font-normal`}
`
export const Content = styled.div`
  ${tw`pt-4 pb-1 pr-8`}
  ${({ active }) => (active ? tw`block` : tw`hidden`)}
  ${({ active, highlight }) => highlight && !active && tw`block`}
`
