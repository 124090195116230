import React from "react"

import { Icon } from "../Icon/Icon"
import { Field, Input } from "./SearchField.styled"

export const SearchField = ({ large, query, handleChange }: Props): JSX.Element => (
  <Field large={large}>
    <Icon name={"search"} size={"m"} colour={"tertiary"} />
    <Input type={"search"} autoFocus value={query} onChange={handleChange} placeholder={"Search She Mentors"} name={"q"} large={large} />
  </Field>
)

export interface Props {
  query?: string
  large?: boolean
  handleChange?: () => void
}
